.nav {
  background-color: #1470af;
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;


}

* {
  margin: 0;
  padding: 0;
}

.menu {
  display: flex;
  width: 50%;
  justify-content: space-around;
  color: white;

}

a {
  color: white;
  text-decoration: none;
  font-family: cursive;

}

.carousel .control-dots {
  position: absolute;
  bottom: 0;
  margin: 10px 0;
  padding: 0;
  text-align: center;
  width: 100%;
  z-index: 1;
  display: none;
}

.linktag {
  display: flex;
  column-gap: 15px;
  align-items: center;

}

.logo {
  width: 50%;
  color: white;
  margin-left: 30px;
}

.icon {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  column-gap: 18px;
}

.imagediv {
  width: 250px;
  height: 250px;

}

.imgs {
  width: 100%;
  border-radius: 200px;
}

.bann {
  height: 500px;
  display: flex;
  justify-content: center;
  column-gap: 40px;
  align-items: center;
  background-color: #1470af;
  color: white;
}

.text {
  width: 50%;
}

.info {
  display: flex;
  justify-content: space-around;
  background-color: #1470af;
}

.finfo {
  width: 40%;

}

.sinfo {
  width: 40%;
}

.hh {
  text-align: center;
  font-weight: 500;
  font-style: oblique;

}

.maininfo {
  background-color: #1470af;
  color: white;
  height: 400px;
}

.footer {
  width: 100%;
  height: 400px;

  background-color: black;
  color: wheat;
  bottom: 0;
  display: flex;
  justify-content: space-around;
  row-gap: 50px;
  align-items: center;
}

.sli {
  display: flex;
  align-items: center;
  justify-content: space-around;
  color: blue;
  height: 400px;
  font-size: x-large;
}

.io {
  display: flex;
  justify-content: center;
  flex-direction: column;
  row-gap: 12px;
  align-items: center;
}

.social {
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 30px;
  text-align: center;
  row-gap: 12px;
}

.cont {
  display: flex;
  margin-right: 30px;
  font-weight: 700;
}

.ti {
  font-size: 70px;
  color: #1470af;
  font-weight: 600;
}

.gradu {
  font-weight: 900;
}

.sli {
  display: flex;
  align-items: center;
  font-style: italic;
}

.cal {
  color: green;
}

.inst {
  color: #ff2c1f
}

.fb {
  color: #1470af;
}

.twi {
  color: #1470af;
}

h3.iwas {
  font-size: 18px;
  font-family: cursive;
}

.malpas {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin: 80px;

}

.fo {
  border-radius: 10px;
  border: 8px solid black;
  height: auto;
  padding: 30px;
  row-gap: 20px;
  flex-direction: column;
  display: flex;
}

.fo input {
  display: flex;
  height: 30px;
  width: 400px;
  text-align: center;
  border-radius: 10px;

}

.map {
  width: 400px;
  height: 270px;
}

.btnsub {
  width: 100px;
  height: 30px;
  border-radius: 10px;
  background-color: black;
  color: white;
  font-size: larger;
  text-align: center;

}

.hea {
  display: flex;
  justify-content: center;
  align-items: center;
}

.submit {
  display: flex;
  justify-content: center;
  align-items: center;
}

.name {
  display: flex;
  align-items: center;
  height: 100px;
}

.imgpro {

  height: 200px;

}

.imgfdajhx {
text-align: center;
  
  margin-top: 60px;
display: flex;
justify-content:center;
align-items: center;
flex-direction: column;
}

.ico {
  width: 400px;
  height: 400px;

}

.prodiv {
  display: flex;
  align-items: center;
  width: 100%;
  height: 310px;
  justify-content: space-around;

}
.blo{
  display: flex;
  justify-content: space-around   ;
  align-items: center;
flex-wrap: wrap;
  margin-top: 65px;
}
.imgfdahx{
display: flex;
justify-content: center;
align-items: center;
flex-wrap:wrap;
text-align: center;
border: 5px solid gray;
width: 550px;
height: 570px;
border-radius: 15px;
}
.bor{
  margin-top: 10px;
  border: 5px solid grey;
  height: 580px;
  width: 500px; 
  border-radius: 15px;
}
.vrd{
  width: 400px;
  height: 400px;


}
.res{
  background-color: #1470af;
  color: white;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;

}
.resume{
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color: #1470af;
  height: 500px;
color: white;
text-align: center;
}

.main {
  width: 100%;
}